import React from "react";
import { FaFacebookF, FaInstagram, FaTwitter, FaLinkedinIn, FaYoutube, FaBehance, FaWhatsapp, FaPhoneAlt } from "react-icons/fa";  // إضافة أيقونة الهاتف

const Footer = () => {
  const currentYear = new Date().getFullYear();  // الحصول على السنة الحالية بشكل ديناميكي

  return (
    <footer className="bg-[#292260] text-white py-8">
      <div className="max-w-6xl mx-auto px-4">
        {/* القسم الأول: روابط الموقع */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8">
          <div>
            <h3 className="text-xl font-semibold mb-4">روابط مهمة</h3>
            <ul>
              <li><a href="/" className="hover:text-indigo-400">من نحن</a></li>
              <li><a href="/contact" className="hover:text-indigo-400">اتصل بنا</a></li>
              <li><a href="/privacy" className="hover:text-indigo-400">الخصوصية</a></li>
              <li><a href="/terms" className="hover:text-indigo-400">الشروط والأحكام</a></li>
            </ul>
          </div>

          {/* القسم الثاني: وسائل التواصل الاجتماعي */}
          <div>
            <h3 className="text-xl font-semibold mb-4">تابعنا على</h3>
            <div className="flex space-x-4">
              <a href="https://www.facebook.com/tharwatdarweshgraphic" target="_blank" rel="noopener noreferrer">
                <FaFacebookF className="text-2xl hover:text-indigo-400 transition-colors" />
              </a>
              <a href="https://www.instagram.com/darweshtharwat/" target="_blank" rel="noopener noreferrer">
                <FaInstagram className="text-2xl hover:text-indigo-400 transition-colors" />
              </a>
              <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">
                <FaYoutube className="text-2xl hover:text-indigo-400 transition-colors" />
              </a>
              <a href="https://www.behance.net" target="_blank" rel="noopener noreferrer">
                <FaBehance className="text-2xl hover:text-indigo-400 transition-colors" />
              </a>
              <a href="https://wa.me/11234567890" target="_blank" rel="noopener noreferrer">
                <FaWhatsapp className="text-2xl hover:text-indigo-400 transition-colors" />
              </a>
            </div>
          </div>

          {/* القسم الثالث: التفاصيل */}
          <div>
            <h3 className="text-xl font-semibold mb-4">تفاصيل الاتصال</h3>
            <p className="mb-2">العنوان: الشارع الشمالي، الفريديس</p>
            <p className="mb-2">البريد الإلكتروني: darweshteam@gmail.com</p>
            <p className="flex items-center space-x-2 mb-2">
              <FaPhoneAlt className="text-xl text-green-400" />
              <span className="text-lg hover:text-green-500 cursor-pointer">
                +972 533411380
              </span>
            </p>
          </div>

          {/* القسم الرابع: حقوق الملكية */}
          <div>
            <h3 className="text-xl font-semibold mb-4">حقوق الملكية</h3>
            <p>© {currentYear} جميع الحقوق محفوظة</p> {/* عرض السنة بشكل ديناميكي */}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
