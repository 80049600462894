import React from "react";
import HeroSection from "./components/HeroSection";
import WorksSection from "./components/WorksSection";
import Footer from "./components/Footer";

import '@fortawesome/fontawesome-free/css/all.min.css';
import ContactSection from "./components/ContactSection";
import Header from "./components/Header";


function App() {
  return (
    <div>
      <Header/>
      <HeroSection />
      <WorksSection />  
      <ContactSection/>
      <Footer />
    </div>
  );
}

export default App;
