import React from 'react'

export default function Services() {
  return (
    <div id='services'>
 <div className="mt-12 text-center text-white px-4">
  <h2 className="text-4xl font-bold mb-4 text-shadow-md">ماذا نقدم لكم</h2>
  <p className="mt-2 text-lg leading-relaxed max-w-3xl mx-auto">
    نقدم مجموعة متنوعة من الخدمات المميزة.
  </p>
</div> 

<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-8 mt-12 text-center">
{/* تصميم جرافيكي احترافي */}
<div className="service-item transform transition-transform duration-300 hover:scale-105">
  <i className="fas fa-paint-brush text-5xl text-white mb-4"></i>
  <h3 className="text-xl font-bold text-white mb-2">تصميم جرافيكي احترافي</h3>
  <p className="text-lg text-white">
    ابتكار تصاميم مميزة تشمل الشعارات، الكتيبات، والهويات البصرية لتعزيز العلامة التجارية.
  </p>
</div>

{/* برمجة التطبيقات ومواقع الويب */}
<div className="service-item transform transition-transform duration-300 hover:scale-105">
  <i className="fas fa-laptop-code text-5xl text-white mb-4"></i>
  <h3 className="text-xl font-bold text-white mb-2">برمجة التطبيقات ومواقع الويب</h3>
  <p className="text-lg text-white">
    تصميم وتطوير التطبيقات ومواقع ويب تفاعلية وسريعة باستخدام أحدث التقنيات.
  </p>
</div>

{/* إدارة المحتوى */}
<div className="service-item transform transition-transform duration-300 hover:scale-105">
  <i className="fas fa-cogs text-5xl text-white mb-4"></i>
  <h3 className="text-xl font-bold text-white mb-2">إدارة المحتوى</h3>
  <p className="text-lg text-white">
    إدارة وتحديث المحتوى بشكل مستمر لضمان تفاعل أفضل مع جمهورك.
  </p>
</div>

{/* إدارة وسائل التواصل الاجتماعي */}
<div  className="service-item transform transition-transform duration-300 hover:scale-105">
  <i className="fas fa-share-alt text-5xl text-white mb-4"></i>
  <h3 className="text-xl font-bold text-white mb-2">إدارة وسائل التواصل الاجتماعي</h3>
  <p className="text-lg text-white">
    تطوير استراتيجيات التواصل الاجتماعي وزيادة التفاعل مع جمهورك على مختلف المنصات.
  </p>
</div>

{/* تصوير وإنتاج الفيديو */}
<div className="service-item transform transition-transform duration-300 hover:scale-105">
  <i className="fas fa-video text-5xl text-white mb-4"></i>
  <h3 className="text-xl font-bold text-white mb-2">تصوير وإنتاج الفيديو</h3>
  <p className="text-lg text-white">
    خدمات التصوير الفوتوغرافي وإنتاج الفيديوهات الإبداعية للترويج لعلامتك التجارية.
  </p>
</div>
</div>
    </div>
 



)
}
