import React, { useState } from "react";
import emailjs from "emailjs-com";

const ContactSection = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [status, setStatus] = useState("");  // لتخزين حالة إرسال الرسالة

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // إرسال البيانات إلى EmailJS
    emailjs
      .send(
        "service_735jb4c", // احصل على Service ID من EmailJS
        "template_mr1j4qd", // احصل على Template ID من EmailJS
        formData, // بيانات النموذج
        "IclkqKs9XQTT2pzKc" // احصل على User ID من EmailJS
      )
      .then(
        (result) => {
          console.log(result.text);
          setStatus("تم إرسال الرسالة بنجاح!");  // تحديث الحالة عند إرسال الرسالة
        },
        (error) => {
          console.log(error.text);
          setStatus("حدث خطأ أثناء إرسال الرسالة. حاول مرة أخرى.");
        }
      );
  };

  return (
    <section id="contact" className="py-16 bg-gradient-to-b from-black to-black text-white px-4">
      <div className="max-w-6xl mx-auto text-center">
        <h2 className="text-4xl sm:text-5xl font-semibold mb-6 text-shadow-lg">
          اتصل بنا
        </h2>
        <p className="text-lg sm:text-xl leading-relaxed text-gray-300 mb-8">
          إذا كان لديك أي استفسار أو ملاحظة، فلا تتردد في التواصل معنا.
        </p>

        {/* حالة الإرسال */}
        {status && (
          <div
            className={`mb-4 text-xl ${status.includes("بنجاح") ? "text-green-500" : "text-red-500"}`}
          >
            {status}
          </div>
        )}

        {/* نموذج الاتصال */}
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-6">
            {/* حقل الاسم */}
            <div className="flex-1">
              <label htmlFor="name" className="block text-sm font-medium mb-2">
                الاسم
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                className="w-full px-4 py-2 border border-gray-300 rounded-lg text-gray-800 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              />
            </div>
            {/* حقل البريد الإلكتروني */}
            <div className="flex-1">
              <label htmlFor="email" className="block text-sm font-medium mb-2">
                البريد الإلكتروني
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                className="w-full px-4 py-2 border border-gray-300 rounded-lg text-gray-800 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              />
            </div>
          </div>

          {/* حقل الرسالة */}
          <div>
            <label htmlFor="message" className="block text-sm font-medium mb-2">
              الرسالة
            </label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
              rows="4"
              className="w-full px-4 py-2 border border-gray-300 rounded-lg text-gray-800 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            ></textarea>
          </div>

          {/* زر الإرسال */}
          <div>
            <button
              type="submit"
              className="w-full bg-indigo-600 text-white py-2 px-4 rounded-lg font-semibold hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              إرسال الرسالة
            </button>
          </div>
        </form>

        {/* زر الواتساب */}
        <a 
          aria-label="Chat on WhatsApp" 
          href="https://wa.me/972533411380" 
          target="_blank" 
          rel="noopener noreferrer"
          className="inline-flex items-center justify-center mt-8 px-6 py-3 bg-green-500 text-white text-lg font-semibold rounded-lg shadow-md hover:bg-green-600 transition duration-300 ease-in-out transform hover:scale-105"
        >
          <img 
            alt="Chat on WhatsApp" 
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/WhatsApp.svg/600px-WhatsApp.svg.png" 
            className="w-8 h-8 mr-3"
          />
          <span>تواصل عبر واتساب</span>
        </a>
      </div>
    </section>
  );
};

export default ContactSection;
