import React from "react";
import peopleImage from "../assets/hero-image.png"; // استيراد صورة الشخصين
import "./HeroSection.css"; // استيراد ملف الـ CSS
import AboutUs from "./AboutUs";
import Services from "./Services";

const HeroSection = () => {
  return (
    <section className="relative min-h-screen flex items-center justify-center bg-gradient-to-br from-primary via-secondary to-background overflow-hidden pt-28">
      {/* خلفية الفيديو */}
      <video
        className="absolute inset-0 w-full h-full object-cover "
        autoPlay
        loop
        muted
        playsInline
      >
        <source src="/hero-background.mp4" type="video/mp4" />
        المتصفح لا يدعم الفيديو.
      </video>

      {/* الطبقة الشفافة بتدرج مخصص */}
      <div className="absolute inset-0 bg-gradient-to-t from-[#292260ff] to-[#292260ee]"></div>

      {/* صورة الأشخاص مع حركة للأعلى وللأسفل */}
      <div className="relative z-10 flex items-center justify-center w-full h-full flex-col text-center">
        <img
          src={peopleImage}
          alt="Persons"
          className="w-3/4 md:w-1/3 lg:w-1/4 custom-radius shadow-xl animate-ping-slow"
          style={{
            WebkitMaskImage: "linear-gradient(to left, transparent, white 10%, white 90%, transparent)",
            animation: "moveUpDown 5s ease-in-out infinite",
          }}
        />

        <AboutUs/>
        <Services/>
        
       
      </div>
    </section>
  );
};

export default HeroSection;
