import React from 'react';
import { FaFacebookF, FaInstagram, FaYoutube, FaBehance, FaWhatsapp } from 'react-icons/fa';  // استيراد الأيقونات
import logo from '../assets/logo.png';  // استيراد الصورة من مجلد assets

const Header = () => {
  return (
    <header className="bg-[#292260] text-white py-4 px-6 shadow-lg fixed top-0 w-full z-50 ">
      <div className="max-w-6xl mx-auto flex justify-between items-center">
        {/* الشعار */}
        <a href="/" className="text-3xl font-bold text-white hover:text-indigo-400">
          <img src={logo} alt="مكتبنا للإبداع" className="max-h-16 w-auto" /> {/* ضبط الحجم المتناسق للشعار */}
        </a>

        {/* قائمة التنقل */}
        <nav className="space-x-6 hidden md:flex">
          <a href="#" className="hover:text-indigo-400">الرئيسية</a>
          <a href="#about-us" className="hover:text-indigo-400">من نحن</a>
          <a href="#services" className="hover:text-indigo-400">خدماتنا</a>
          <a href="#works" className="hover:text-indigo-400">أعمالنا</a>
          <a href="#contact" className="hover:text-indigo-400">اتصل بنا</a>
        </nav>

        {/* أزرار التواصل الاجتماعي */}
        <div className="space-x-4 flex items-center">
          <a href="https://www.facebook.com/tharwatdarweshgraphic" target="_blank" rel="noopener noreferrer">
            <FaFacebookF className="text-2xl hover:text-indigo-400 transition-colors" />
          </a>
          <a href="https://www.instagram.com/darweshtharwat/" target="_blank" rel="noopener noreferrer">
            <FaInstagram className="text-2xl hover:text-indigo-400 transition-colors" />
          </a>
          <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">
            <FaYoutube className="text-2xl hover:text-indigo-400 transition-colors" />
          </a>
          <a href="https://www.behance.net" target="_blank" rel="noopener noreferrer">
            <FaBehance className="text-2xl hover:text-indigo-400 transition-colors" />
          </a>
          <a href="https://wa.me/972533411380" target="_blank" rel="noopener noreferrer">
            <FaWhatsapp className="text-2xl hover:text-indigo-400 transition-colors" />
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
