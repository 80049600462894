import React from 'react'

export default function AboutUs() {
  return (
    <div id='about-us' className="mt-6 text-center text-white px-4 mb-8">
    <h2 className="text-4xl font-bold mb-4 text-shadow-md">نبذة عن مكتبنا</h2>
    <p className="mt-2 text-lg leading-relaxed max-w-3xl mx-auto">
      في مكتبنا، يجتمع الشغف والإبداع لخلق حلول مبتكرة ترتقي بعلامتك التجارية إلى مستويات جديدة.
      نحن نؤمن بأن التصميم والتسويق هما الأدوات السحرية لتحقيق أفضل النتائج.
      <br />
      نعمل جاهدين على تقديم خدمات عالية الجودة تناسب جميع احتياجاتك وتحقق النجاح الذي تطمح إليه.
    </p>
  </div>
  )
}
