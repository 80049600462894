import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const WorksSection = () => {
  const images = ['/1.jpeg', '/2.jpeg', '/3.jpeg', '/4.jpeg', '/5.jpeg', '/6.jpeg', '/7.jpeg', '/8.jpeg'];

  const settings = {
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    centerMode: true,
    centerPadding: '20px',
    responsive: [
      {
        breakpoint: 1280, // شاشات كبيرة
        settings: {
          slidesToShow: 4,
          centerPadding: '20px',
        },
      },
      {
        breakpoint: 1024, // شاشات متوسطة
        settings: {
          slidesToShow: 3,
          centerPadding: '15px',
        },
      },
      {
        breakpoint: 768, // الأجهزة اللوحية
        settings: {
          slidesToShow: 2,
          centerPadding: '10px',
        },
      },
      {
        breakpoint: 640, // الهواتف الصغيرة
        settings: {
          slidesToShow: 1,
          centerMode: false,
          centerPadding: '0',
        },
      },
    ],
  };

  return (
    <section id="works" className="relative py-12 md:py-16 lg:py-20 bg-gradient-to-b from-[#292260] to-black">
      {/* خلفية التدرج */}
      <div className="absolute inset-0 bg-gradient-to-b from-[#292260] to-black opacity-80"></div>

      {/* محتوى القسم */}
      <div className="container mx-auto px-4 relative z-10">
        {/* العنوان والوصف */}
        <div className="text-center text-white mb-8 md:mb-12">
          <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold mb-4 text-shadow-lg">
            من أعمالنا
          </h2>
          <p className="text-sm sm:text-base md:text-lg max-w-2xl mx-auto text-gray-300">
            استمتع بمشاهدة بعض من أعمالنا المميزة التي حققناها عبر السنوات
          </p>
        </div>

        {/* معرض الصور */}
        <div className="w-full overflow-hidden">
          <Slider {...settings}>
            {images.map((image, index) => (
              <div 
                key={index} 
                className="px-2 md:px-3 lg:px-4"
              >
                <div className="bg-white/10 rounded-xl p-2 transform transition duration-300 hover:scale-105">
                  <img
                    src={image}
                    alt={`Work ${index + 1}`}
                    className="w-full h-48 md:h-64 lg:h-80 object-cover rounded-lg shadow-2xl"
                    loading="lazy"
                  />
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default WorksSection;